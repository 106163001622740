import cloneDeep from 'lodash/cloneDeep'
import { useEffect, useState } from 'react'
import {
    ContentActionView,
    ContentOutcomeView,
    EnrichedContent,
} from '../../api/client'
import ContentCard from '../content/content-card/ContentCard'

export default function SpamContentList({
    item,
}: {
    item: ContentOutcomeView
    message: string
}) {
    // We keep a list of content as we maintain the action state locally and avoid a refetch.
    const [content, setContent] = useState(item)

    const handleContentAction = function (contentActions: ContentActionView[]) {
        if (contentActions.length === 1) {
            // The existing content needs to be updated when an action is performed
            if (content.id === contentActions[0].id) {
                // Update the content based on the current action
                const cloned = cloneDeep(content)
                cloned.actions.push(...contentActions)
                setContent(cloned)
            }
        } else {
            // Handler called without an action - should not happen
            console.error('Content action handler - no action provided. ')
        }
    }

    useEffect(() => {
        setContent(item)
    }, [item])

    return (
        <>
            {content && content.account ? (
                <div className="flex h-full w-full flex-col" key={content.id}>
                    <ContentCard
                        key={content.id}
                        content={content}
                        showAccount={true}
                        onContentAction={handleContentAction}
                        enriching={false}
                        translating={false}
                        profileCache={[]}
                        enrichedContentMap={new Map<number, EnrichedContent>()}
                        actionCacheMap={new Map<number, ContentActionView[]>()}
                        isSelected={false}
                        onSelectionChange={() => {}}
                    />
                </div>
            ) : null}
        </>
    )
}
