export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

/**
 * The name in our social media service enums might not map exactly to
 * a name in the font awesome library.
 * @param socialMediaServiceName
 * @returns An icon name, like 'fa-tiktok'
 */
export function faIconName(socialMediaServiceName: string | undefined) {
    let faIconName = ''
    switch (socialMediaServiceName) {
        case 'all platforms':
            break
        case 'tikapi':
            faIconName = 'fa-tiktok'
            break
        case 'twitter':
            faIconName = 'fa-x-twitter'
            break
        case 'tiktokad':
            faIconName = 'fa-tiktok'
            break
        case 'tiktokuser':
            faIconName = 'fa-tiktok'
            break
        default:
            faIconName = `fa-${socialMediaServiceName?.toLocaleLowerCase()}`
    }
    return faIconName
}
