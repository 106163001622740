import { useState } from 'react'
import {
    ContentClassifierMetadataView,
    SearchParamsForContentOutcome,
    useGetContentClassifierMetadataQuery,
} from '../../../api/client'
import { SearchParameters } from './ContentSearchBar'
import FilterInput, { FilterItem } from './FilterInput'

type Props = {
    searchParameters: SearchParameters
    onUpdateSelectedClassifiers: (classifiers: Array<string>) => void
}
type Classifier = {
    name: string
    displayName: string
    description: string
}

const ALL_CLASSIFIERS: Classifier[] = [
    // DS: both of the following are used by most customers.
    {
        name: 'spectrum_hate-speech',
        displayName: 'Hate speech',
        description: '',
    },
    {
        name: 'identity_attack',
        displayName: 'Identity attack',
        description: '',
    },
    // Used by a lot of customers.
    { name: 'spam', displayName: 'Spam', description: '' },
    // These are used but they are less commonly used.
    {
        name: 'severe_toxicity',
        displayName: 'Severe toxicity',
        description: '',
    },
    { name: 'insult', displayName: 'Insult', description: '' },
    { name: 'profanity', displayName: 'Profanity', description: '' },
    { name: 'toxicity', displayName: 'Toxicity', description: '' },
    { name: 'threat', displayName: 'Threat', description: '' },
    { name: 'keyword_racist', displayName: 'Racist keyword', description: '' },
    // Given the way we query with thresholds here this has to be just positivity
    { name: 'sentiment', displayName: 'Positivity', description: '' },
    {
        name: 'blocked_keywords',
        displayName: 'Blocked keywords',
        description: '',
    },
    // Used only for family brands
    { name: 'spectrum_sexual', displayName: 'Sexual', description: '' },

    {
        name: 'spectrum_self-harm',
        displayName: 'Self-harm',
        description: '',
    },

    // The following are not used, no longer supported or used a lot less and omitted to keep the list clean.
    // - spectrum_insult
    // - self_harm
    // - physical_violence
    // - sexual_aggression
    // - white_supremacist_extremism
    // - illicit_drugs'
    // - sexually_explicit
    // - flirtation
    // - nli/threat
    // - spectrum_bullying
]

export default function GeneralClassifierFilter(props: Props) {
    const { searchParameters, onUpdateSelectedClassifiers } = props
    const [searchTerm, setSearchTerm] = useState('')

    const selectedClassifiers: Classifier[] = ALL_CLASSIFIERS.filter(
        (classifier) =>
            searchParameters.selectedGeneralClassifiers.includes(
                classifier.name
            )
    )

    const parameters: SearchParamsForContentOutcome = {
        accountIds: searchParameters.selectedAccountIds,
        teamIds: searchParameters.selectedTeams,
        moderationString: [searchParameters.moderation?.toLowerCase()],
        since: searchParameters.startDateTime,
        before: searchParameters.endDateTime,
        socialMediaServiceIds:
            searchParameters.selectedSocialMediaServices?.map(
                (service) => service.id
            ) || [],
        containsText: searchParameters.keyword,
        classifierSearchParams: searchParameters.selectedClassifiers,
    }

    const { data, loading } = useGetContentClassifierMetadataQuery({
        variables: {
            params: parameters,
        },
    })

    const classifierDataResult = (data?.getContentClassifierMetadata ||
        []) as ContentClassifierMetadataView[]

    let contentClassifierMetadata: ContentClassifierMetadataView
    if (classifierDataResult.length > 0) {
        contentClassifierMetadata = classifierDataResult[0]
    }

    function handleUpdateSelectedClassifiers(selectedItems: Array<FilterItem>) {
        //@ts-ignore
        onUpdateSelectedClassifiers(selectedItems.map((item) => item.name))
    }

    function mapClassifierToFilterItem(
        classifier: Classifier,
        index: number
    ): FilterItem {
        if (contentClassifierMetadata && !loading) {
            const classifierMetadata =
                // @ts-ignore
                contentClassifierMetadata[classifier.name] || 0

            return {
                id: index,
                name: `${classifier.name}`,
                displayName: `${classifier.displayName} (${classifierMetadata})`,
            }
        }
        return {
            id: index,
            name: `${classifier.name}`,
            displayName: `${classifier.displayName}`,
        }
    }

    const selectedItems = selectedClassifiers.map(mapClassifierToFilterItem)
    const searchResults = ALL_CLASSIFIERS.filter((classifier) =>
        classifier.displayName.toLowerCase().includes(searchTerm.toLowerCase())
    ).map(mapClassifierToFilterItem)

    return (
        <FilterInput
            filterTypeName={`Classifiers`}
            searchTerm={searchTerm}
            onSearchTermChange={async (term) => {
                setSearchTerm(term)
            }}
            onUpdateSelectedItems={handleUpdateSelectedClassifiers}
            selectedItems={selectedItems}
            searchResults={searchResults}
            searching={loading}
            hideName={true}
        />
    )
}
