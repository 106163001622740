import { useSearchParams } from 'react-router-dom'
import {
    PROFILE_PARAM_NAME,
    SocialMediaServiceFilterOptions,
} from '../models/SocialMediaServiceSearch'
import { faIconName } from '../util/classNames'
import ArwenListBox, { ArwenListBoxItem } from './inputs/ArwenListBox'
import { Dispatch, SetStateAction } from 'react'

interface Props {
    setOffset?: Dispatch<SetStateAction<number>>
}
/**
 * A drop-down that allows the user to select a social media service.
 *
 * Puts the selected service in the URL.
 */
function PlatformDropdown(props: Props) {
    let [searchParams, setSearchParams] = useSearchParams()
    const searchParam = searchParams.get(PROFILE_PARAM_NAME)
    const searchTerm = searchParam ? searchParam : 'All Platforms'

    // See comments for `setParam` in frontend/src/components/SearchInput.tsx for why we do it this way.
    const setParam = (value: string) => {
        const newSearchParams = new URLSearchParams(searchParams.toString())
        newSearchParams.set(PROFILE_PARAM_NAME, value)
        setSearchParams(newSearchParams)
        props.setOffset && props.setOffset(0)
    }

    let onSelectHandler = (option: ArwenListBoxItem) => {
        setParam(option.name)
    }

    // Build the options from the available social media services
    let options: ArwenListBoxItem[] = Object.values(
        SocialMediaServiceFilterOptions
    ).map((name, index) => {
        return {
            id: String(index),
            name: name,
            icon: faIconName(name.toLowerCase()),
        }
    })

    // Set the selected value to the system search parameters≥

    const value: ArwenListBoxItem =
        options.find((value) => {
            return value.name.toLowerCase() === `${searchTerm.toLowerCase()}`
        }) || options[0]

    return (
        <div className="text-gray-950 flex w-44 ">
            <ArwenListBox
                onSelect={onSelectHandler}
                options={options}
                value={value}
                widthClass="w-44"
            />
        </div>
    )
}
export default PlatformDropdown
