import React, { useState } from 'react'
import {
    AccountRecord,
    useSetAutoHideSevereContentMutation,
} from '../../api/client'
import { AutoAction, getAutoActions } from './autoActions'
import Toggle from './Toggle'

const AutoHideSevereContentToggle = ({
    account,
}: {
    account: AccountRecord
}) => {
    const [setAutoHideSevereContentMutation, { loading }] =
        useSetAutoHideSevereContentMutation()
    const [enabled, setEnabled] = useState(account.autoHideSevereContent)

    const onToggle = async (isEnabled: boolean) => {
        setEnabled(isEnabled)
        await setAutoHideSevereContentMutation({
            variables: {
                id: Number(account.id),
                autoHideSevereContent: !enabled,
            },
        })
    }

    if (
        getAutoActions(account.socialMediaServiceDisplayName || '').includes(
            AutoAction.Hide
        )
    ) {
        return (
            <Toggle
                id="AutoHideSevere"
                disabled={false}
                checked={enabled}
                onToggle={onToggle}
                label="Auto hide"
                loading={loading}
                tooltip="If enabled this will automatically hide tweets on the user's threads that we have classified as 'severe'."
                data-cy="auto_hide_toggle"
                size="large"
            />
        )
    } else return <></>
}

export default AutoHideSevereContentToggle
