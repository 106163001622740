import { sub } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { AccountRecord, useGetSummaryForAccountsQuery } from '../api/client'
import { MODERATION_QUERY_PARAM } from '../models/QueryParameters'
import { fetchTimePeriodState } from '../store/UIStore'
import { faIconName } from '../util/classNames'
import AuthoriseButton from './authorise/AuthoriseButton'
import AutoFetchContentToggle from './toggles/AutoFetchContentToggle'
import ScrapingToggle from './toggles/ScrapingToggle'
import TwitterActivityApiToggle from './toggles/TwitterActivityApiToggle'
import DashboardButton from './account/DashboardButton'

type Props = {
    account: AccountRecord
    admin?: boolean
    showAdminFeatures?: boolean
}

function AccountRow(props: Props) {
    const { account, admin, showAdminFeatures } = props
    const fetchTimePeriodMinutes = useRecoilValue(fetchTimePeriodState)

    const [startDateTime, setStartDateTime] = useState<Date>(
        sub(new Date(), {
            minutes: fetchTimePeriodMinutes * 60 * 24,
        })
    )
    const [endDateTime, setEndDateTime] = useState<Date>(new Date())

    useEffect(() => {
        setStartDateTime(
            sub(new Date(), {
                minutes: fetchTimePeriodMinutes * 60 * 24,
            })
        )

        setEndDateTime(new Date())
    }, [fetchTimePeriodMinutes])

    const { data, loading } = useGetSummaryForAccountsQuery({
        variables: {
            params: {
                accountIds: [account.id],
                teamIds: [],
                fromDatePosted: startDateTime,
                toDatePosted: endDateTime,
                socialMediaServiceIds: [],
            },
        },
    })

    const viewAll = (moderation: string) => {
        return (
            <Link
                to={`/account/${account.id}/?${MODERATION_QUERY_PARAM}=${moderation}&profile=${account.id}`}
                className={` text-primary`}
            >
                View all
            </Link>
        )
    }

    let countSafe = ''
    let countSevere = ''
    let countSuspect = ''
    if (data?.getSummaryForAccounts?.countSafe != null) {
        countSafe = `${
            data.getSummaryForAccounts.countSafe
                ? data.getSummaryForAccounts.countSafe
                : '0'
        }`
        countSuspect = `${
            data.getSummaryForAccounts.countSuspect
                ? data.getSummaryForAccounts.countSuspect
                : '0'
        }`
        countSevere = `${
            data.getSummaryForAccounts.countSevere
                ? data.getSummaryForAccounts.countSevere
                : '0'
        }`
    }

    function generateStatDisplay(
        moderation: string,
        count: string,
        classes: string
    ) {
        return (
            <td className="px-6 py-4 whitespace-nowrap text-center text-sm ">
                <div className="text-sm">
                    <div className={`${classes} text-lg font-medium`}>
                        {count}
                    </div>
                    {viewAll(moderation)}
                </div>
            </td>
        )
    }

    // We don't have auto-fetch functionality for Facebook Pages.
    let showAutoFetchContent =
        admin &&
        account.socialMediaServiceShortName &&
        account.socialMediaServiceShortName !== 'facebook'

    let showTwitterActivityApiToggle =
        admin &&
        account.hasAuthorisation &&
        account.socialMediaServiceShortName &&
        account.socialMediaServiceShortName === 'twitter'

    let showScrapingToggle =
        admin &&
        account.socialMediaServiceShortName &&
        account.socialMediaServiceShortName === 'twitter'

    let iconName = faIconName(account.socialMediaServiceShortName || '')

    function generateAdminColumn() {
        return (
            <td
                className="px-6 py-4 whitespace-nowrap text-right text-xs 
         "
            >
                {showAutoFetchContent ? (
                    <AutoFetchContentToggle account={account} size="small" />
                ) : null}

                {showTwitterActivityApiToggle ? (
                    <TwitterActivityApiToggle account={account} />
                ) : null}
                {showScrapingToggle ? (
                    <ScrapingToggle account={account} size="small" />
                ) : null}
            </td>
        )
    }

    return (
        <React.Fragment>
            <tr className="hover:bg-gray-50" key={account.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                        <div
                            className="flex-shrink-0 h-10 w-10"
                            title={account.socialMediaServiceShortName || ''}
                        >
                            <i className={`fab ${iconName} text-primary`}></i>
                        </div>
                        <div className="ml-4">
                            <div className=" text-gray-950">
                                {account.accountName}
                                <AuthoriseButton
                                    account={account}
                                    onlyWhenUnauthorised
                                />
                            </div>
                            <div className="text-sm text-gray-700">
                                {account.serviceAccountUsername}
                            </div>
                        </div>
                    </div>
                </td>

                {loading ? (
                    <>
                        <td className="">
                            <div className="flex flex-row justify-center">
                                <div className="animate-pulse w-12 bg-gray-300 h-12 rounded-md "></div>
                            </div>
                        </td>
                        <td className="">
                            <div className="flex flex-row justify-center">
                                <div className="animate-pulse w-12 bg-gray-300 h-12 rounded-md "></div>
                            </div>
                        </td>
                        <td className="">
                            <div className="flex flex-row justify-center">
                                <div className="animate-pulse w-12 bg-gray-300 h-12 rounded-md "></div>
                            </div>
                        </td>
                    </>
                ) : (
                    <>
                        {generateStatDisplay('safe', countSafe, 'text-safe')}
                        {generateStatDisplay(
                            'suspect',
                            countSuspect,
                            'text-suspect'
                        )}
                        {generateStatDisplay(
                            'severe',
                            countSevere,
                            'text-severe'
                        )}
                    </>
                )}

                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex flex-row mt-1 gap-2">
                    <DashboardButton accountId={account.id} />
                </td>

                {admin && showAdminFeatures ? (
                    <>
                        {generateAdminColumn()}{' '}
                        <td className="px-6 py-4 whitespace-nowrap text-center text-sm">
                            <div className="text-sm">
                                {account.teamName && (
                                    <div>Team: {account.teamName}</div>
                                )}
                                <div>Owned by: {account.ownedBy}</div>
                            </div>
                        </td>
                    </>
                ) : null}
            </tr>
        </React.Fragment>
    )
}

export default AccountRow
