import { useState } from 'react'
import { formatDistanceToNow, formatISO9075 } from 'date-fns'
import { ContentOutcomeView, EnrichedContent } from '../../../../api/client'
import { getInLocalTime } from '../../../../util/timeHelpers'
import InfoButton from '../../../actions/buttons/InfoButton'
import ContentMetadataModal from './ContentMetadataModal'
import mixpanel from 'mixpanel-browser'

type Props = {
    content: ContentOutcomeView
    enrichedContent: EnrichedContent | undefined
}

export default function ContentMetadata(props: Props) {
    const [open, setOpen] = useState(false)
    const { content, enrichedContent } = props

    /**
     * The name we want to display might be a handle, e.g. '@jamescolepotter' or
     * it might be a name, e.g. James Cole Pottery. This depends on the service.
     */
    return (
        <div className="flex flex-row">
            {content.serviceAuthorName && (
                <div className="pr-2 font-medium">
                    {content.serviceAuthorName}
                </div>
            )}
            {content.serviceAuthorDisplayUsername &&
                content.socialMediaAuthorLink && (
                    <div className="text-primary-600 hover:text-primary-900 pr-1">
                        <a
                            href={content.socialMediaAuthorLink || ''}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {content.serviceAuthorDisplayUsername}
                        </a>
                    </div>
                )}
            {content.serviceAuthorDisplayUsername &&
                !!!content.socialMediaAuthorLink && (
                    <div className="pr-1 text-gray-700">
                        {content.serviceAuthorDisplayUsername}
                    </div>
                )}

            <div className="pr-1 text-gray-700">·</div>
            <div
                className="pr-1 text-gray-700"
                data-cy-date={content.datePosted}
            >
                {content.datePosted
                    ? formatDistanceToNow(getInLocalTime(content.datePosted), {
                          addSuffix: true,
                      })
                    : null}
            </div>
            <div className="pr-1 text-gray-600">
                (
                {content.datePosted
                    ? formatISO9075(getInLocalTime(content.datePosted))
                    : null}
                )
            </div>
            <div>
                <InfoButton
                    tooltip="Show all details"
                    onActionClick={() => {
                        mixpanel.track('Content - info button clicked', {
                            accountId: content.account?.id,
                            contentId: content.id,
                        })
                        setOpen(true)
                    }}
                />
            </div>
            <ContentMetadataModal
                open={open}
                setOpen={setOpen}
                content={content}
                enrichedContent={enrichedContent}
            />
        </div>
    )
}
