import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import {
    AccountRecord,
    SocialMediaServiceEnum,
    useFetchHistoricYouTubeVideosMutation,
    useGetAccountQuery,
    useGetYouTubeSubscriptionDiagnosticUrlLazyQuery,
} from '../api/client'
import Button from '../components/Button'
import DeleteAccountModal from '../components/DeleteAccountModal'
import { toastError, toastSuccess } from '../components/Notification'
import AccountTeamSelector from '../components/account/AccountTeamSelector'
import FetchHistoricMeta from '../components/account/FetchHistoricMeta'
import AuthoriseButton from '../components/authorise/AuthoriseButton'
import AutoDeleteSevereContentToggle from '../components/toggles/AutoDeleteSevereContentToggle'
import AutoFetchContentToggle from '../components/toggles/AutoFetchContentToggle'
import AutoHideSevereContentToggle from '../components/toggles/AutoHideSevereContentToggle'
import AutoMuteSevereAccountToggle from '../components/toggles/AutoMuteSevereAccountToggle'
import LlmClassificationToggle from '../components/toggles/LlmClassificationToggle'
import ProfileCollectionToggle from '../components/toggles/ProfileCollectionToggle'
import ProfileModerationToggle from '../components/toggles/ProfileModerationToggle'
import ScrapingToggle from '../components/toggles/ScrapingToggle'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'
import { isPlatformAppReviewerState } from '../store/DomainStore'
import { getUserPermissions } from '../util/auth'
import LlmReplyToggle from '../components/toggles/LlmReplyToggle'
import DuplicateModerationConfiguration from '../components/moderation/DuplicateModerationConfiguration'

type ParamTypes = {
    accountId: string
}

/**
 * Checks whether this account is a meta account (i.e. Instagram or Facebook)
 */
function isMeta(account: AccountRecord) {
    const socialMediaService =
        account.socialMediaServiceShortName?.toLowerCase()
    return (
        socialMediaService === SocialMediaServiceEnum.Instagram.toLowerCase() ||
        socialMediaService === SocialMediaServiceEnum.Facebook.toLowerCase()
    )
}

/**
 * This page holds all the settings for a given account.
 */
function AccountSettingsPage() {
    const { accountId } = useParams<ParamTypes>()
    const [admin, setAdmin] = useState(false)
    const [isPlatformAppReviewer] = useRecoilState(isPlatformAppReviewerState)
    const [deleteAccountModalVisible, setDeleteAccountModalVisible] =
        useState(false)

    const [fetchHistoricYouTubeVideos] = useFetchHistoricYouTubeVideosMutation()
    const [getYouTubeSubscriptionDiagnosticUrl, { data: subscriptionData }] =
        useGetYouTubeSubscriptionDiagnosticUrlLazyQuery()

    const { data: accountData, refetch } = useGetAccountQuery({
        variables: { accountId: parseInt(accountId as string) },
    })

    const account = accountData?.getAccount

    let showMetaFetch = false
    if (account) {
        showMetaFetch = isMeta(account) ? isPlatformAppReviewer || admin : false
    }

    useEffect(() => {
        if (account) {
            getYouTubeSubscriptionDiagnosticUrl({
                variables: { channelId: account.serviceAccountId },
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    useEffect(() => {
        async function determineIfAdmin() {
            const userPermissions = await getUserPermissions()

            setAdmin(userPermissions.isAdmin)
        }
        determineIfAdmin()
    }, [])

    let diagnosticUrl = useRef('')
    useEffect(() => {
        const url = subscriptionData?.getYouTubeSubscriptionDiagnosticUrl?.url
        if (url) {
            diagnosticUrl.current = url
        }
    }, [subscriptionData])

    const handleDeleteClick = () => {
        setDeleteAccountModalVisible(true)
    }

    const handleTeamChange = () => {
        refetch()
    }

    return (
        <TextPageContainer>
            <TextPageHeader title="Profile settings" />
            <Link to={`/account/${accountId}`}>Back to profile</Link>
            {account && (
                <>
                    <div className="flex flex-col mb-32">
                        <h2>Team</h2>
                        <AccountTeamSelector
                            account={account}
                            onTeamChange={handleTeamChange}
                        />
                        <h2>Moderation</h2>
                        <div className="max-w-60 w-60">
                            <AutoMuteSevereAccountToggle account={account} />
                            <AutoHideSevereContentToggle account={account} />
                            <AutoDeleteSevereContentToggle account={account} />
                        </div>
                        {!account.hasAuthorisation && (
                            <>
                                <h2>Authorisation</h2>
                                <AuthoriseButton
                                    account={account}
                                    onlyWhenUnauthorised={true}
                                />
                            </>
                        )}
                        <h2>Admin</h2>
                        <Link
                            to={`/account/${accountId}/moderation-boundaries`}
                            className="ml-3"
                        >
                            Edit moderation boundaries...
                        </Link>
                        <Link
                            to={`/account/${accountId}/keywords`}
                            className="ml-3"
                        >
                            Edit keywords...
                        </Link>

                        <DuplicateModerationConfiguration account={account} />

                        <h3>LLM Settings</h3>
                        <div className="max-w-96 w-96">
                            <LlmClassificationToggle account={account} />
                        </div>
                        <Link
                            to={`/account/${accountId}/settings/llm-classify`}
                            className="ml-3"
                        >
                            Edit LLM classify settings...
                        </Link>
                        <div className="max-w-96 w-96">
                            <LlmReplyToggle account={account} />
                        </div>
                        <Link
                            to={`/account/${accountId}/settings/llm-reply`}
                            className="ml-3"
                        >
                            Edit LLM reply settings...
                        </Link>

                        {account.socialMediaServiceShortName?.toLowerCase() ===
                            SocialMediaServiceEnum.Tikapi.toLowerCase() && (
                            <>
                                <h3>TikApi</h3>
                                <div className="max-w-96 w-96">
                                    <AutoFetchContentToggle
                                        account={account}
                                        size="large"
                                    />
                                </div>
                            </>
                        )}
                        {account.socialMediaServiceShortName?.toLowerCase() ===
                            SocialMediaServiceEnum.Twitter.toLowerCase() && (
                            <>
                                <h3>X</h3>
                                <div className="max-w-96 w-96">
                                    <AutoFetchContentToggle
                                        account={account}
                                        size="large"
                                    />
                                    <ScrapingToggle
                                        account={account}
                                        size="large"
                                    />
                                    <Link
                                        to={`/account/${accountId}/fetch-historic`}
                                        className="ml-3"
                                    >
                                        Fetch historic X content...
                                    </Link>
                                </div>
                            </>
                        )}
                        {account.socialMediaServiceShortName?.toLowerCase() ===
                            SocialMediaServiceEnum.Instagram.toLowerCase() && (
                            <>
                                <h3>Instagram settings</h3>
                                <div className="w-4/5">
                                    <ProfileCollectionToggle
                                        account={account}
                                    />
                                    <ProfileModerationToggle
                                        account={account}
                                    />
                                </div>
                            </>
                        )}
                        {account.socialMediaServiceShortName?.toLowerCase() ===
                            SocialMediaServiceEnum.Youtube.toLowerCase() && (
                            <>
                                <h3>YouTube settings</h3>
                                <div className="w-4/5">
                                    <AutoFetchContentToggle
                                        account={account}
                                        size="large"
                                    />
                                </div>
                                <Link
                                    key={account.id}
                                    to={`/account/${accountId}/authorisation/revoke`}
                                    className="ml-3"
                                >
                                    Revoke authorisation...
                                </Link>
                                <a
                                    href={diagnosticUrl.current}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="ml-3"
                                >
                                    <span className="flex">
                                        YouTube subscription diagnostics page...
                                        <ArrowTopRightOnSquareIcon className="w-4" />
                                    </span>
                                </a>
                                <div className="flex flex-col gap-y-3 ml-3 mt-3">
                                    {/* I've removed the following buttons as they are now obsolete and haven't been used in years
                                    - Add or renew YouTube post subscription
                                    - Refresh access token
                                     */}
                                    <div>
                                        <span className="text-gray-600 text-sm mb-1">
                                            We only fetch comments on videos we
                                            know about. We get sent videos via
                                            our webhook, but that means we only
                                            have videos that were created since
                                            the profile was added to Arwen. Our
                                            customers frequently want us to
                                            collect comments on existing videos.
                                            This function uses the API to fetch
                                            older videos, so we can fetch older
                                            comments, and indeed new comments on
                                            older videos. This action fetches 5
                                            years of posts and could take up to
                                            45 minutes.
                                        </span>

                                        <div className="w-80">
                                            <Button
                                                primary={false}
                                                text="Fetch historic YouTube videos"
                                                onClick={() =>
                                                    fetchHistoricYouTubeVideos({
                                                        variables: {
                                                            accountId:
                                                                account.id,
                                                        },
                                                        onCompleted: () => {
                                                            toastSuccess(
                                                                'Submitted fetch request for historic YouTube videos.'
                                                            )
                                                        },
                                                        onError: (error) => {
                                                            console.error(error)
                                                            toastError(
                                                                'Unable to request historic YouTube videos! See console for errors.'
                                                            )
                                                        },
                                                    })
                                                }
                                            ></Button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {showMetaFetch && (
                            <>
                                <h3>Meta</h3>
                                <div className="w-80 ml-3">
                                    <FetchHistoricMeta account={account} />
                                </div>
                            </>
                        )}
                        <h2>Delete this profile</h2>
                        <p className="ml-3 text-gray-600 text-sm">
                            Deleting a profile can take some time, e.g. 15-30
                            minnutes. Once deleted this profile will disappear
                            from the profile list but will remain in a hidden
                            state until all its content and classifications have
                            been deleted. During this time of you try to add the
                            profile again you will get an error
                        </p>
                        {deleteAccountModalVisible ? (
                            <DeleteAccountModal
                                setVisible={setDeleteAccountModalVisible}
                                accountId={account.id}
                            />
                        ) : null}
                        <div className="w-80 ml-3">
                            <Button
                                primary={false}
                                onClick={handleDeleteClick}
                                text="Delete profile forever"
                            />
                        </div>
                    </div>
                </>
            )}
        </TextPageContainer>
    )
}

export default AccountSettingsPage
