export function whichPageAreWeOn(paths: string[]) {
    // Which page are we on? Possible paths:
    return {
        onHomePage: paths.length === 2 && paths[1] === '',
        onTeamPage: paths.length === 2 && paths[1] === 'team',
        onContentSearchPage:
            paths.length === 2 && paths[1] === 'content-search',
        onReportPage: paths.length === 2 && paths[1] === 'report',
        onContactUsPage: paths.length === 2 && paths[1] === 'support',
        onAboutPage: paths.length === 2 && paths[1] === 'about',
        onSpamPage: paths.length === 2 && paths[1] === 'spam-model',
        onAccountPage: paths.length > 2 && paths[1] === 'account',
        onTeamAdminPage: paths.length === 3 && paths[2] === 'team',
        onUserAdminPage: paths.length === 3 && paths[2] === 'user',
        onIntelligencePage: paths.length === 2 && paths[1] === 'intelligence',
        onPresalesReportPage:
            paths.length === 2 && paths[1] === 'presales-report',
    }
}
