import { EyeSlashIcon } from '@heroicons/react/24/outline'
import {
    ContentAction,
    ContentActionView,
    ContentOutcomeView,
} from '../../../api/client'
import ActionGenericButton from './GenericActionButton'

type Props = {
    content: ContentOutcomeView
    onActionClick: (actions: ContentActionView[], queued?: boolean) => void
    actions: ContentActionView[]
    actionCacheMap: Map<number, ContentActionView[]>
}

export default function HideButton(props: Props) {
    const { content, onActionClick, actionCacheMap } = props

    // Just for twitter
    // We can only hide Twitter content if it is in "owned" media. We can determine this by checking to
    // see if the tweet is in a thread which is owned by our customer (by serviceAccountId)
    // Please see content DAO for the full logic.

    // Either owned is true or is_owned (in the db is null). This prevents hiding the button for non-twitter
    // and content older than the logic for determining owned content.
    const owned = content.isOwned === null || content.isOwned
    const cannotHideMessage =
        content?.socialMediaServiceName.toLowerCase() === 'twitter' && !owned
    if (cannotHideMessage) {
        return (
            <div
                data-tip={`This tweet is not in reply
                        to your thread, it cannot be
                        hidden.`}
                className={`ml-2 w-10 rounded-full p-2 opacity-40`}
            >
                <EyeSlashIcon />
            </div>
        )
    }

    // Identify the relevant actions from the action cache map
    const allExistingContentActions = actionCacheMap.get(content.id) || []

    return (
        <ActionGenericButton
            content={content}
            onActionClick={onActionClick}
            action={ContentAction.Hide}
            tooltip="Hide/unhide this content"
            colour="blue"
            icon={<EyeSlashIcon />}
            actions={allExistingContentActions}
        />
    )
}
