import { AccountRecord, SocialMediaService } from '../../../api/client'
import {
    ClassifierSearchParam,
    getClassifierSearchParam,
} from '../../../models/ClassifierSearchParam'
import {
    EMOTIONS_LIST,
    EMOTION_THRESHOLD,
    TOPICS_LIST,
    TOPICS_THRESHOLD,
} from '../../../pages/IntelligencePage'
import SummaryStatistics from '../../SummaryStatistics'
import SearchInput from '../../SearchInput'
import AccountFilter from './AccountFilter'
import DateTimePickerContainer from './DateTimePickerContainer'
import EmotionFilter from './EmotionFilter'
import SocialMediaServiceFilter from './SocialMediaServiceFilter'
import TeamFilter from './TeamFilter'
import TopicFilter from './TopicFilter'
import TranslationSettings from '../../translation/TranslationSettings'
import GeneralClassifierFilter from './GeneralClassifierFilter'
import mixpanel from 'mixpanel-browser'

export type SearchParameters = {
    selectedAccountIds: number[]
    startDateTime: Date
    endDateTime: Date
    keyword: string
    moderation: string
    selectedSocialMediaServices: SocialMediaService[]
    selectedTeams: number[]
    selectedEmotions: string[]
    selectedTopics: string[]
    selectedGeneralClassifiers: string[]
    selectedClassifiers: ClassifierSearchParam[]
    account?: AccountRecord
    selectedSuggestedReplies: string
    selectedReplies: string
}

type Props = {
    searchParameters: SearchParameters
    onSearchParameterChange: (searchParams: SearchParameters) => void
    allSocialMediaServices: SocialMediaService[]

    showProfileSearch: boolean
    showSocialMediaServiceSearch: boolean
    showTeamSearch: boolean
    showEmotionsSearch: boolean
    showTopicsSearch: boolean
    account?: AccountRecord
}

export default function ContentSearchBar(props: Props) {
    const {
        searchParameters,
        onSearchParameterChange,
        allSocialMediaServices,

        showProfileSearch,
        showSocialMediaServiceSearch,
        showTeamSearch,
        showEmotionsSearch,
        showTopicsSearch,
        account,
    } = props

    return (
        <div className="px-4 py-4 flex flex-row flex-wrap gap-x-4 gap-y-4 ">
            {showTeamSearch && (
                <TeamFilter
                    teamIds={searchParameters.selectedTeams}
                    onUpdateSelectedTeams={(teamIds: number[]) => {
                        mixpanel.track('Search - content - team', { teamIds })
                        onSearchParameterChange({
                            ...searchParameters,
                            selectedTeams: teamIds,
                            selectedAccountIds: [],
                        })
                    }}
                />
            )}
            {showSocialMediaServiceSearch && (
                <SocialMediaServiceFilter
                    onSocialMediaServiceListChange={(
                        socialMediaServiceIds: number[]
                    ) => {
                        mixpanel.track(
                            'Search - content - social media service',
                            { socialMediaServiceIds }
                        )
                        onSearchParameterChange({
                            ...searchParameters,
                            selectedSocialMediaServices:
                                allSocialMediaServices.filter((service) =>
                                    socialMediaServiceIds.includes(service.id)
                                ),
                        })
                    }}
                    allSocialMediaServices={allSocialMediaServices}
                    selectedSocialMediaServices={
                        searchParameters.selectedSocialMediaServices
                    }
                />
            )}
            {showProfileSearch && (
                <AccountFilter
                    selectedAccountIds={searchParameters.selectedAccountIds}
                    selectedTeamIds={searchParameters.selectedTeams}
                    onUpdateSelectedAccounts={(accounts: AccountRecord[]) => {
                        mixpanel.track('Search - content - profiles', {
                            accountIds: accounts.map((account) => account.id),
                        })
                        onSearchParameterChange({
                            ...searchParameters,
                            selectedAccountIds: accounts.map(
                                (account) => account.id
                            ),
                        })
                    }}
                />
            )}
            <SummaryStatistics
                onModerationChange={(moderation: string) => {
                    mixpanel.track('Search - content - moderation', {
                        moderation,
                    })
                    onSearchParameterChange({ ...searchParameters, moderation })
                }}
                startDateTime={searchParameters.startDateTime}
                endDateTime={searchParameters.endDateTime}
                selectedModeration={searchParameters.moderation}
                accountIds={
                    account ? [account.id] : searchParameters.selectedAccountIds
                }
                teamIds={searchParameters.selectedTeams}
                selectedSocialMediaServices={
                    searchParameters.selectedSocialMediaServices
                }
                keyword={searchParameters.keyword}
                selectedClassifiers={[
                    ...searchParameters.selectedEmotions.map((emotion) =>
                        getClassifierSearchParam(emotion, EMOTION_THRESHOLD)
                    ),
                    ...searchParameters.selectedTopics.map((topic) =>
                        getClassifierSearchParam(topic, TOPICS_THRESHOLD)
                    ),
                ]}
            />

            <SearchInput
                keyword={searchParameters.keyword}
                onKeywordChange={(keyword: string) => {
                    mixpanel.track('Search - content - keyword', { keyword })
                    onSearchParameterChange({ ...searchParameters, keyword })
                }}
                onSearch={(keyword: string) => {
                    mixpanel.track('Search - content - keyword', { keyword })
                    onSearchParameterChange({ ...searchParameters, keyword })
                }}
            />

            <div className="flex flex-shrink flex-row flex-wrap rounded-md border border-gray-300 bg-white shadow-md">
                <DateTimePickerContainer
                    startDateTime={searchParameters.startDateTime}
                    setStartDateTime={(date: Date) => {
                        mixpanel.track('Search - content - start date time', {
                            startDateTime: date.toISOString(),
                        })
                        onSearchParameterChange({
                            ...searchParameters,
                            startDateTime: date,
                        })
                    }}
                    endDateTime={searchParameters.endDateTime}
                    setEndDateTime={(date: Date) => {
                        mixpanel.track('Search - content - end date time', {
                            endDateTime: date.toISOString(),
                        })
                        onSearchParameterChange({
                            ...searchParameters,
                            endDateTime: date,
                        })
                    }}
                    onDateRangeChange={(
                        startDateTime: Date,
                        endDateTime: Date
                    ) => {
                        mixpanel.track('Search - content - date range', {
                            startDateTime: startDateTime.toISOString(),
                            endDateTime: endDateTime.toISOString(),
                        })
                        onSearchParameterChange({
                            ...searchParameters,
                            startDateTime,
                            endDateTime,
                        })
                    }}
                />
            </div>
            <GeneralClassifierFilter
                searchParameters={searchParameters}
                onUpdateSelectedClassifiers={(classifiers: string[]) => {
                    mixpanel.track('Search - content - classifier', {
                        classifiers,
                    })
                    onSearchParameterChange({
                        ...searchParameters,
                        selectedGeneralClassifiers: classifiers,
                    })
                }}
            />

            {showEmotionsSearch && !!searchParameters.selectedEmotions && (
                <EmotionFilter
                    allEmotions={EMOTIONS_LIST}
                    selectedEmotions={searchParameters.selectedEmotions}
                    onUpdateSelectedEmotions={(emotions: string[]) => {
                        mixpanel.track('Search - content - emotion', {
                            emotions,
                        })
                        onSearchParameterChange({
                            ...searchParameters,
                            selectedEmotions: emotions,
                        })
                    }}
                />
            )}

            {showTopicsSearch && !!searchParameters.selectedTopics && (
                <TopicFilter
                    allTopics={TOPICS_LIST}
                    selectedTopics={searchParameters.selectedTopics}
                    onUpdateSelectedTopics={(topics: string[]) => {
                        mixpanel.track('Search - content - topic', { topics })
                        onSearchParameterChange({
                            ...searchParameters,
                            selectedTopics: topics,
                        })
                    }}
                />
            )}
            <TranslationSettings />
            <div className="flex flex-row items-center">
                {/* This component will be used in the second step of this task */}
                {/* <SuggestedRepliesToggle
                    searchParameters={searchParameters}
                    onSearchParameterChange={onSearchParameterChange}
                /> */}
            </div>
            {/* This component will be used in the second step of this task */}
            {/* <RepliesSelectDropDown
                searchParameters={searchParameters}
                onSearchParameterChange={onSearchParameterChange}
            /> */}
        </div>
    )
}
