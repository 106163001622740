import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
    ContentOutcomeView,
    useGetLabelledSpamLazyQuery,
    useSearchContentOutcomeByIdLazyQuery,
} from '../../api/client'
import PlatformDropdown from '../../components/PlatformDropdown'
import SimpleLoader from '../../components/SimpleLoader'
import ErrorCallout from '../../components/errors/ErrorCallout'
import SpamContentList from '../../components/spam/SpamContentList'
import {
    PROFILE_PARAM_NAME,
    SocialMediaServiceFilterOptions,
    dropDownToGraphQLEnum,
} from '../../models/SocialMediaServiceSearch'
import { toastError } from '../../components/Notification'

function SpamModelPage() {
    const [keyword, setKeyword] = useState('')

    const [searchParams] = useSearchParams()
    const socialMediaServiceParam = searchParams.get(
        PROFILE_PARAM_NAME
    ) as keyof typeof SocialMediaServiceFilterOptions
    let socialMediaService = dropDownToGraphQLEnum(socialMediaServiceParam)

    const [spamItems, setSpamItems] = useState([] as ContentOutcomeView[])
    const [spamId, setSpamId] = useState<number>()

    const [fetchLabelledSpam, { loading, error }] = useGetLabelledSpamLazyQuery(
        {
            onCompleted: (data) => {
                setSpamId(data.getLabelledSpam!)
            },
            onError: (error) => {
                toastError(error.message)
            },
        }
    )

    const [errorFetchingContent, setErrorFetchingContent] = useState(false)

    const [
        getContentById,
        { data: contentData, loading: loadingContent, refetch: refetchContent },
    ] = useSearchContentOutcomeByIdLazyQuery({
        onCompleted: (data) => {
            setSpamItems(data?.getContentById! as ContentOutcomeView[])
        },
        onError: (error) => {
            if (spamId) {
                toastError(error.message)
                setErrorFetchingContent(true)
            }
        },
    })

    async function handleSearch() {
        const items = await fetchLabelledSpam({
            variables: {
                message: keyword,
                platform: socialMediaService,
            },
        })

        setSpamId(items.data?.getLabelledSpam!)
        const content = await getContentById({
            variables: {
                id: items.data?.getLabelledSpam!,
            },
        })

        setSpamItems(content.data?.getContentById! as ContentOutcomeView[])
    }

    useEffect(() => {
        refetchContent({
            id: spamId,
        })

        setSpamItems(contentData?.getContentById! as ContentOutcomeView[])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentData, spamId])

    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        const newKeyword = event.currentTarget.value
        setKeyword(newKeyword)
    }

    return (
        <>
            <div className="mx-10 mt-10 prose prose-stone prose-lg ">
                <h2 className="text-gray-950 initial">
                    Manage the content of the spam model
                </h2>
                <p>
                    This page lets you search for and remove content from the
                    spam model. You can also use this page to experiment to find
                    content that might be identified as spam.
                </p>
                <p>
                    <strong>NOTE:</strong> items will take 15-30 minutes to be
                    removed from the spam model.
                </p>
                <p>Start by entering the phrase you want to search for...</p>
            </div>
            <div className="mx-10 mb-10">
                <div className="mr-1 mt-1">
                    <PlatformDropdown />
                </div>
                <div className="stretchy w-3/4">
                    <div className="flex items-start">
                        <div className="min-w-0 flex-1">
                            <form className="relative m-6">
                                {/* <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-800 focus-within:ring-2 focus-within:ring-primary-600 border-1"> */}
                                <label htmlFor="comment" className="sr-only">
                                    Add your comment
                                </label>
                                <textarea
                                    rows={3}
                                    id="comment"
                                    className="bg-white block w-full resize-none overflow-hidden rounded-lg shadow-sm  border-1 py-1.5 text-gray-950 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder="Add your comment..."
                                    defaultValue={''}
                                    onChange={(event) => handleChange(event)}
                                />
                                {/* Spacer element to match the height of the toolbar */}
                                <div
                                    className="py-2 bg-white"
                                    aria-hidden="true"
                                >
                                    {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                    <div className="py-px">
                                        <div className="h-9" />
                                    </div>
                                </div>
                                {/* </div> */}
                                <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                                    <div className="flex items-center space-x-5">
                                        <div className="flex items-center"></div>
                                        <div className="flex items-center"></div>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <button
                                            onClick={async () => {
                                                await handleSearch()
                                            }}
                                            className="inline-flex items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>{' '}
            </div>
            <div className="mx-10 mb-10">
                <SimpleLoader loading={loading} />
                {error || errorFetchingContent ? (
                    <ErrorCallout message=" There was an error fetching the spam content" />
                ) : null}
                {!loadingContent && spamItems && spamItems.length > 0 ? (
                    <div className="self-center pt-8">
                        <SpamContentList
                            item={spamItems[0] as ContentOutcomeView}
                            message={keyword}
                        />
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default SpamModelPage
