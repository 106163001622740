import { useState } from 'react'
import {
    ContentAction,
    ContentActionView,
    ContentOutcomeView,
    useTakeContentActionMutation,
} from '../../../api/client'
import { isActionApplied } from '../../content/content-card/actions'
import BaseActionButton from './BaseActionButton'
import { mapToContentActionView } from '../../../models/ContentAction'
import { toastError } from '../../Notification'

type Props = {
    content: ContentOutcomeView
    action: ContentAction
    tooltip: string
    colour: string
    icon: JSX.Element
    onActionClick: (actions: ContentActionView[], queued?: boolean) => void
    actions: ContentActionView[]
}

/**
 * A "generic" action is one which uses the takeAction mutation.
 *
 * Some actions, like "like" or "reply" are more complex and have dedicated
 * endpoints. Some like "hide", "mute", etc are "generic" and share the same
 * endpoint.
 *
 * This uses the BaseActionButton at its core.
 */
export default function GenericActionButton(props: Props) {
    const { content, action, tooltip, colour, icon, onActionClick, actions } =
        props

    const actionQueued = actions.some(
        (contentActionView) =>
            contentActionView.action.toLowerCase() ===
                ContentAction.Hide.toLowerCase() && contentActionView.queued
    )

    const [isActionQueued, setActionQueued] = useState(actionQueued)

    const shouldAlsoHide = function (
        action: ContentAction,
        alreadyApplied: boolean,
        alreadyHidden: boolean
    ) {
        if (
            // If a user is marking content as spam we should also hide it.
            action === ContentAction.ConfirmSpam &&
            !alreadyApplied &&
            !alreadyHidden
        ) {
            return true
        } else {
            return false
        }
    }

    let displayTooltip = tooltip

    if (isActionQueued) {
        displayTooltip = `${tooltip}. This action is queued.`
    }

    const [takeContentActionMutation, { loading: loadingTakeContentAction }] =
        useTakeContentActionMutation({
            onError: (error) => {
                // FIXME: Add handling for different error scenarios e.g. deleted content or unauthorised account
                toastError(error.message)
            },
        })

    const actionAlreadyApplied = isActionApplied(actions, action)
    const hiddenActionAlreadyApplied = isActionApplied(
        actions,
        ContentAction.Hide
    )

    return (
        <div className="relative">
            <BaseActionButton
                tooltip={displayTooltip}
                loading={loadingTakeContentAction}
                colour={colour}
                actionAlreadyApplied={actionAlreadyApplied}
                icon={icon}
                onActionClick={async () => {
                    // Set up additional action in case we want to take multiple actions
                    let additionalHideAction
                    let isHiddenQueued
                    if (
                        shouldAlsoHide(
                            action,
                            actionAlreadyApplied,
                            hiddenActionAlreadyApplied
                        )
                    ) {
                        // Try to hide the comment if required
                        const hiddenResult = await takeContentActionMutation({
                            variables: {
                                contentId: content.id,
                                action: ContentAction.Hide,
                                toggle: !actionAlreadyApplied,
                                baseUrl: `${window.location.protocol}//${window.location.host}/`,
                            },
                        })
                        if (!!!hiddenResult.errors) {
                            if (
                                hiddenResult.data &&
                                hiddenResult.data.takeContentAction
                            ) {
                                isHiddenQueued =
                                    hiddenResult.data.takeContentAction.queued
                                additionalHideAction =
                                    hiddenResult.data?.takeContentAction
                                        ?.contentAction
                            }
                        }
                    }
                    const result = await takeContentActionMutation({
                        variables: {
                            contentId: content.id,
                            action,
                            toggle: !actionAlreadyApplied,
                            baseUrl: `${window.location.protocol}//${window.location.host}/`,
                        },
                    })
                    if (!!!result.errors) {
                        if (result.data && result.data.takeContentAction) {
                            const isQueued =
                                result.data.takeContentAction.queued
                            const newAction =
                                result.data?.takeContentAction?.contentAction

                            const queuedActions = []
                            const takenActions = []

                            if (newAction) {
                                const newActionMapped =
                                    mapToContentActionView(newAction)
                                const hiddenActionMapped = additionalHideAction
                                    ? mapToContentActionView(
                                          additionalHideAction
                                      )
                                    : null

                                if (isQueued) {
                                    queuedActions.push(newActionMapped)
                                } else {
                                    takenActions.push(newActionMapped)
                                }

                                if (isHiddenQueued && hiddenActionMapped) {
                                    queuedActions.push(hiddenActionMapped)
                                } else if (hiddenActionMapped) {
                                    takenActions.push(hiddenActionMapped)
                                }

                                if (queuedActions.length > 0) {
                                    setActionQueued(isQueued)
                                    onActionClick(
                                        queuedActions,
                                        result.data?.takeContentAction.queued
                                    )
                                } else {
                                    onActionClick(takenActions)
                                }
                            }
                        }
                    }
                }}
                isActionQueued={isActionQueued}
            />
        </div>
    )
}
