import { userState } from '../../store/DomainStore'
import { useRecoilValue, useRecoilState } from 'recoil'
import { selectedAccountState } from '../../store/UIStore'
import { classNames, faIconName } from '../../util/classNames'

type Props = {
    showLogo: boolean
}

function PageTitle({ showLogo }: Props) {
    const [user] = useRecoilState(userState)
    const selectedAccount = useRecoilValue(selectedAccountState)

    const accountName = selectedAccount?.accountName
    const accountService = selectedAccount?.socialMediaServiceDisplayName

    const accountNameDisplay =
        accountName && accountService ? `${accountName}` : ''

    const showAccountDetails = !!selectedAccount
    const showTeamDetails = user && user?.team?.name

    let iconName = faIconName(
        selectedAccount?.socialMediaServiceShortName || ''
    )
    const accountDisplay = showAccountDetails ? (
        <>
            {accountNameDisplay}
            <i
                data-tip={`This content comes from from ${selectedAccount.socialMediaServiceDisplayName}`}
                className={`fab ml-4 ${iconName}`}
            ></i>{' '}
        </>
    ) : null

    const teamDisplay = showTeamDetails ? user.team.name : null

    const teamAccountDelimiterDisplay =
        showTeamDetails && showAccountDetails ? ' - ' : null

    return (
        <div className="mt-2 text-gray-700 flex self-center ">
            <h1
                className={classNames(
                    showLogo ? 'pl-14' : 'pl-0',
                    'text-3xl text-gray-600 font-header'
                )}
            >
                {teamDisplay}
                {teamAccountDelimiterDisplay}
                {accountDisplay}
            </h1>
        </div>
    )
}

export default PageTitle
