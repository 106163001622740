import { ArrowSmallDownIcon, ArrowSmallUpIcon } from '@heroicons/react/24/solid'
import { Header, HeaderGroup, Table, flexRender } from '@tanstack/react-table'

type Props = {
    table: Table<any>
}

/**
 * This is the most basic Arwen table and is used for displaying tabstack/react-table
 * data. It is independent of the shape of the data being displayed.
 */
export default function SimpleTable(props: Props) {
    const { table } = props

    return (
        <table>
            <thead>
                {table
                    .getHeaderGroups()
                    .map((headerGroup: HeaderGroup<any>) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(
                                (header: Header<any, any>) => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    <div
                                                        {...{
                                                            className:
                                                                header.column.getCanSort()
                                                                    ? 'cursor-pointer select-none'
                                                                    : '',
                                                            onClick:
                                                                header.column.getToggleSortingHandler(),
                                                        }}
                                                    >
                                                        {flexRender(
                                                            header.column
                                                                .columnDef
                                                                .header,
                                                            header.getContext()
                                                        )}
                                                        {{
                                                            asc: (
                                                                <ArrowSmallUpIcon className="mb-1 ml-1 h-4 w-4 inline" />
                                                            ),
                                                            desc: (
                                                                <ArrowSmallDownIcon className="mb-1 ml-1 h-4 w-4 inline" />
                                                            ),
                                                        }[
                                                            header.column.getIsSorted() as string
                                                        ] ?? null}
                                                    </div>
                                                </>
                                            )}
                                        </th>
                                    )
                                }
                            )}
                        </tr>
                    ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                        {footerGroup.headers.map((header) => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.footer,
                                          header.getContext()
                                      )}
                            </th>
                        ))}
                    </tr>
                ))}
            </tfoot>
        </table>
    )
}
