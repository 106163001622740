import { Link, useParams } from 'react-router-dom'
import {
    useGetAccountQuery,
    useGetLlmClassificationPromptLazyQuery,
    useSaveLlmClassificationPromptMutation,
    useTestLlmClassificationPromptLazyQuery,
} from '../api/client'
import { useTitle } from '../components/TitleProvider'
import PromptDetailsForm from '../components/prompt/PromptDetailsForm'
import LlmClassificationToggle from '../components/toggles/LlmClassificationToggle'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'
import { useEffect } from 'react'
import { toastError, toastSuccess } from '../components/Notification'
import WarningCallout from '../components/callout/WarningCallout'
import SimpleLoader from '../components/SimpleLoader'

export default function LlmClassifySettingsPage() {
    useTitle('LLM Classification Settings')
    let { accountId } = useParams()
    const [
        getLlmClassificationPrompt,
        { data: getPromptData, loading: getPromptLoading },
    ] = useGetLlmClassificationPromptLazyQuery({
        onError: (error) => {
            toastError(
                `Failed to load LLM classification prompt: ${error.message}`
            )
        },
    })

    const [saveLlmClassificationPrompt, { loading: savePromptLoading }] =
        useSaveLlmClassificationPromptMutation({
            onCompleted: () => {
                toastSuccess('Prompt saved')
            },
            onError: (error) => {
                toastError(
                    `Failed to save LLM classification prompt: ${error.message}`
                )
            },
        })

    const [
        testLlmClassificationPrompt,
        { data: testResultData, loading: testResultLoading },
    ] = useTestLlmClassificationPromptLazyQuery({
        onError: (error) => {
            toastError(
                `Failed to test LLM classification prompt: ${error.message}`
            )
        },
    })

    const { data: accountData, loading: accountLoading } = useGetAccountQuery({
        variables: { accountId: parseInt(accountId as string) },
    })
    const account = accountData?.getAccount

    useEffect(() => {
        if (account) {
            getLlmClassificationPrompt({
                variables: {
                    accountId: account.id,
                },
                fetchPolicy: 'no-cache',
            })
        }
    }, [account, getLlmClassificationPrompt])

    const initialPrompt =
        getPromptData?.getLlmClassificationPrompt?.prompt || ''

    const testPromptResult = testResultData
        ? Math.round(
              (testResultData?.testLlmClassificationPrompt + Number.EPSILON) *
                  100
          ) / 100
        : undefined

    const handleOnTest = async (prompt: string, message: string) => {
        await testLlmClassificationPrompt({
            variables: {
                prompt: prompt,
                testMessage: message,
            },
        })
    }

    const handleOnSave = async (prompt: string) => {
        if (account) {
            await saveLlmClassificationPrompt({
                variables: {
                    accountId: account.id,
                    prompt: prompt,
                },
            })
        } else {
            console.error('Account not loaded yet.')
            toastError('Unable to save prompt - please try again')
        }
    }

    return (
        <TextPageContainer>
            <TextPageHeader title="LLM classify settings pages" />
            <Link to={`/account/${accountId}/settings`}>Back to settings</Link>

            {accountLoading && <SimpleLoader loading={true} />}

            {account && (
                <div className="flex flex-col mb-32 mt-10">
                    <div className="max-w-96 w-96">
                        <LlmClassificationToggle account={account} />
                    </div>
                    {account && !account.llmClassificationEnabled && (
                        <WarningCallout>
                            LLM classification and moderation for this profile
                            is NOT enabled
                        </WarningCallout>
                    )}

                    <div className="w-full ml-3">
                        {accountLoading && getPromptLoading && (
                            <SimpleLoader loading={true} />
                        )}
                        {!accountLoading && !getPromptLoading && (
                            <PromptDetailsForm
                                prompt={initialPrompt}
                                saving={savePromptLoading}
                                testing={testResultLoading}
                                testResult={testPromptResult}
                                onSave={handleOnSave}
                                onTest={handleOnTest}
                            />
                        )}
                    </div>
                </div>
            )}
        </TextPageContainer>
    )
}
