import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { ContentOutcomeView, EnrichedContent } from '../../../api/client'
import mixpanel from 'mixpanel-browser'

type Props = {
    content: ContentOutcomeView
    enrichedContent: EnrichedContent | undefined
    enriching: boolean
}

type GetSocialMediaContentLink = {
    (
        enrichedContent: EnrichedContent | undefined,
        content: ContentOutcomeView
    ): string | undefined
}

/** THIS IS TECHNICAL DEBT
 * This function is used to generate a link to the original content.
 * We've now moved this to `content_link.rs:get_social_media_content_link` => in Rust code
 * HOWEVER - content arrives from content search missing some of the fields that are used to generate the link.
 * In `ContentList.tsx` we do a fetch to get this data from the server which then goes out to the social media services
 * to fetch the required bits. This happens in Tenmoku - we need to migrate this to the Rust code and use the rust function
 * above to generate the.
 *
 * Until then we need to reproduce the logic to generate the url here.
 */
export const getSocialMediaContentLink: GetSocialMediaContentLink = function (
    enrichedContent,
    content
) {
    let link: string | undefined

    if (enrichedContent && content.account) {
        switch (content.socialMediaServiceName.toLowerCase()) {
            case 'twitter':
                /*
               usernameForUrl should ideally be the screen name of the tweet author (sometimes known as handle/username)
               However, as long as the usernameForUrl is a non-empty string of valid characters (alphanumeric/hyphen) then twitter will successfully redirect to the correct tweet.
               The below logic removes non-valid characters from the string and if the resulting string is empty it replaces it with 'username' which is a valid string.
               This is to make use of the redirect where mapping has failed.
               */
                let usernameForURL: string
                if (
                    enrichedContent.serviceAuthorName &&
                    enrichedContent.serviceAuthorName.replace(/\W/g, '')
                        .length > 0
                ) {
                    usernameForURL = enrichedContent.serviceAuthorName.replace(
                        /\W/g,
                        ''
                    )
                } else {
                    usernameForURL = 'username'
                }
                link = `https://twitter.com/${usernameForURL}/status/${content.serviceContentId}`
                break
            case 'instagram':
                if (
                    !!!enrichedContent.originalPostLinkId ||
                    !!!content.serviceContentId
                ) {
                    // We don't have sufficient information to form a link to the original post.
                    break
                } else {
                    link = `https://www.instagram.com/p/${enrichedContent.originalPostLinkId}/c/${content.serviceContentId}`
                }
                break
            case 'facebook':
                link = content.originalPostLink || '#'
                break
            case 'youtube':
                link = content.originalPostLink || '#'
                break
            case 'tikapi':
                // We won't display the link for TikTok content because we cannot link directly to TikTok comments.
                break
            default:
                console.error(
                    `Unknown social media service when getting a content link! It was ${content.socialMediaServiceName.toLowerCase}`
                )
        }
    }
    return link
}

export default function ContentLink(props: Props) {
    const { content, enrichedContent, enriching } = props

    const getSocialMediaContentName = function () {
        if (content.socialMediaServiceName.toLowerCase() === 'twitter') {
            return 'Tweets'
        } else if (
            content.socialMediaServiceName.toLowerCase() === 'instagram'
        ) {
            return 'comments'
        }
        return 'content'
    }

    let showInTooltipText = `Hidden ${getSocialMediaContentName()} can still be accessed through this link`

    const socialMediaContentLink =
        content.socialMediaContentLink ||
        getSocialMediaContentLink(enrichedContent, content)

    const isLinkSupportedBySocialMediaService =
        content.socialMediaServiceName.toLowerCase() !== 'tikapi'

    return (
        <div>
            {content.isPublic &&
                isLinkSupportedBySocialMediaService &&
                socialMediaContentLink && (
                    <div>
                        {enriching ? (
                            <div className="flex h-full w-full animate-pulse flex-row content-center items-stretch justify-center space-x-5 border-solid border-gray-300 pr-5">
                                <div className="h-4 w-24 rounded-sm bg-gray-200"></div>
                            </div>
                        ) : (
                            <div className="text-primary hover:text-primary-900 px-2">
                                {socialMediaContentLink ? (
                                    <a
                                        data-tip={showInTooltipText}
                                        href={socialMediaContentLink}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            mixpanel.track(
                                                'Content - link clicked',
                                                { link: socialMediaContentLink }
                                            )
                                        }}
                                    >
                                        <span className="flex">
                                            See original{' '}
                                            <ArrowTopRightOnSquareIcon className="ml-1 w-4" />
                                        </span>
                                    </a>
                                ) : null}
                            </div>
                        )}
                    </div>
                )}
        </div>
    )
}
