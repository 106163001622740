import { startCase } from 'lodash'
import { ContentClassificationView } from '../../../api/client'
import { Queue } from '../../../models/Moderation'
import { convertSeverityToPercentage } from '../../../util/classifierListUtils'

type Props = {
    queue: Queue
    classification: ContentClassificationView
}

export default function ClassificationDisplay(props: Props) {
    const { queue, classification } = props

    const SENTIMENT_NAME = 'sentiment'

    // Lets sort our colours out...
    let textColour = 'text-severe-800'
    let backgroundColour = 'bg-severe-50'
    let indicatorColour = 'text-severe'
    let borderColour = 'border-severe-100'
    if (queue === 'SUSPECT') {
        textColour = 'text-suspect-800'
        backgroundColour = 'bg-suspect-50'
        indicatorColour = 'text-suspect'
        borderColour = 'border-suspect-100'
    } else if (queue === 'SAFE') {
        textColour = `text-safe-800`
        backgroundColour = 'bg-safe-50'
        indicatorColour = 'text-safe'
        borderColour = 'border-safe-100'
    }

    // Generate the display values
    let classificationDisplay = ''
    let classificationPercentageValue = 0
    if (classification?.severity) {
        classificationDisplay = classification.severity

        // We want to use the radial progress display we requires a
        // percentage. So lets convert the numerical severity into a percentage...
        classificationPercentageValue = convertSeverityToPercentage(
            classification.severity
        )
    } else if (classification?.classification) {
        if (classification?.classifierName === SENTIMENT_NAME) {
            // The display is used for the tooltip
            classificationDisplay =
                (classification?.classification * 100).toFixed(0) + '%'
            classificationPercentageValue = -classification.classification
        } else {
            // The display is used for the tooltip
            classificationDisplay =
                (classification?.classification * 100).toFixed(0) + '%'
            classificationPercentageValue = classification.classification
        }
    }

    // The classifierName can look like BLOCKED_KEYWORD so lets use startCase to make it look nice.
    let classificationName = startCase(classification.classifierName)
    if (classification.classifierName.toLowerCase().startsWith('spectrum')) {
        // If it starts with Spectrum then lets get rid of that and just use the rest of the name.
        classificationName = startCase(
            classification.classifierName.substring('Spectrum'.length)
        )
    }

    if (classification.classifierName === 'llm_01') {
        classificationName = 'Arwen Customise'
    }

    // Configure the radial progress settings
    const width = 25
    const height = 25
    const viewBox = `0 0 ${width} ${height}`
    const radius = 11
    const strokeWidth = 3
    const percentage = (classificationPercentageValue || 0) * 100
    // stroke-dasharray = circumference  = 2 * PI * radius
    const circumference = 2 * 3.141592 * radius
    // stroke-dashoffset = circumference - (circumference * percentage) / 100
    const offset = circumference - (circumference * percentage) / 100

    return (
        <div key={classification.classifierName} className="flex flex-row ">
            <div
                data-tip={`${classificationName} classified as ${classificationDisplay.toLowerCase()}  `}
                className={`drop-shadow-sm inline-flex items-center gap-x-1.5 rounded-full  ${backgroundColour} ${textColour} border  ${borderColour} px-2 py-1 text-xs font-medium `}
            >
                <div className="font-semibold">{classificationName}</div>

                <div className="relative w-4 h-4">
                    <svg className="w-full h-full" viewBox={viewBox}>
                        <circle
                            className="text-gray-200 stroke-current"
                            stroke-width={strokeWidth}
                            cx={width / 2}
                            cy={height / 2}
                            r={radius}
                            fill="transparent"
                        ></circle>

                        <circle
                            className={`${indicatorColour} progress-ring__circle stroke-current`}
                            stroke-width={strokeWidth}
                            stroke-linecap="round"
                            cx={width / 2}
                            cy={height / 2}
                            r={radius}
                            fill="transparent"
                            stroke-dasharray={circumference}
                            stroke-dashoffset={offset}
                        ></circle>
                    </svg>
                </div>
            </div>
        </div>
    )
}
