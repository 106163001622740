import { Account, AccountRecord } from '../api/client'

/**
 * This mapper needs to exist because we are currently using two different APIs
 * with different shapes for the same data.
 *
 * The original API is Tenmoku written in Typescript. The new API is written in
 * Rust. We are slowly migrating all code to the Rust API.
 *
 * While we do this there exist some endpoints that use the Tenmoku API and some
 * that use the Rust API but for the same resource. In this case the resource is
 * an Account. Until we have fully migrated we need to be able to map between
 * the two types. That is what this function does.
 *
 * @param account A Tenmoku Account type
 * @returns a Rust type of account which is used everywhere else in the codebase
 */
export default function mapFromTenmokuAccountToAccountRecord(
    account: Account
): AccountRecord {
    return {
        id: account.id,
        accountName: account.accountName,
        autoDeleteSevereContent: account.autoDeleteSevereContent,
        autoHideSevereContent: account.autoHideSevereContent,
        autoMuteSevereAccount: account.autoMuteSevereAccount,
        createdOn: account.createdOn,
        fetchContent: account.autoFetchContent,
        hasAuthorisation: account.authorised,
        isSubscribed: account.isSubscribed,
        lastFetched: account.lastFetched,
        llmClassificationEnabled: account.llmClassificationEnabled,
        llmEnableSuggestedReplies: account.llmEnableSuggestedReplies,
        ownedBy: account.ownedBy,
        profileCollectionEnabled: account.profileCollectionEnabled,
        profileModerationEnabled: account.profileModerationEnabled,
        scrapeContent: account.scrapeContent,
        serviceAccountId: account.serviceAccountId,
        serviceAccountUsername: account.serviceAccountUsername,
        socialMediaServiceDisplayName: account.socialMediaService.displayName,
        socialMediaServiceId: account.socialMediaService.id,
        socialMediaServiceShortName: account.socialMediaService.shortName,
        teamId: account.team?.id,
        teamName: account.team?.name,

        // These are not present in the Tenmoku API.
        pageId: undefined,
        latestFetchedServiceContentId: undefined,
        updatedOn: undefined,
        userId: -1,
        classifyContent: true,
    }
}
